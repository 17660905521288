import React, { useState, useEffect, useRef } from "react";
import { Checkbox, Menu, Dropdown, Image } from "semantic-ui-react";
import styled from "styled-components";
import { getCookie, setCookie } from "utils";
import { createPortal } from "react-dom";

import { api } from "api";
import { FollowList } from "comps/user";

const IS_DARK_INITIAL = getCookie("dark") !== "disabled";

export const PageFixed = () => {
  const [render, setRender] = useState(false);
  useEffect(() => {
    setRender(true);
  }, []);
  if (!render) return null;
  return (
    <>
      <Header />
      <DarkFooter />
    </>
  );
};

const Header = () => {
  const [searchActive, setSearchActive] = useState(false);
  const [searchLoading, setSearchLoading] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [searchResults, setSearchResults] = useState([]);

  useEffect(() => {
    if (!searchQuery) return;
    setSearchLoading(true);
    api.user.search(searchQuery).then(({ resp, data }) => {
      setSearchResults(data.users);
      setSearchLoading(false);
    });
  }, [searchQuery]);
  const user = window.REACT_CONTEXT.request_user;
  const headerEl = document.getElementsByTagName("header")[0];
  let HeaderInner;
  if (user) {
    HeaderInner = (
      <>
        <Menu secondary fixed="top">
          <Menu.Item href="/beta/">
            <h1>Fabric</h1>
          </Menu.Item>
          <Menu.Menu position="right">
            <Menu.Item
              href="/app/feed/"
              active={
                window.location.pathname === "/app/feed/" &&
                window.location.search === ""
              }
            >
              Feed
            </Menu.Item>
            <Menu.Item
              href="/app/feed/?discover=1"
              active={
                window.location.pathname === "/app/feed/" &&
                window.location.search === "?discover=1"
              }
            >
              Discover
            </Menu.Item>
            {user.can_post && (
              <Menu.Item
                active
                href="/app/post/"
                active={window.location.pathname === "/app/post/"}
              >
                Post
              </Menu.Item>
            )}
            <Menu.Item onClick={() => setSearchActive(true)}>
              <i className="ui icon search"></i>
            </Menu.Item>
            <Dropdown
              item
              trigger={<Image avatar src={user.image_url} />}
              style={{ background: null }}
            >
              <Dropdown.Menu>
                <Dropdown.Item href={user.url}>Profile</Dropdown.Item>
                <Dropdown.Item href="/auth/logout">Logout</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </Menu.Menu>
        </Menu>
        {searchActive && (
          <div className="user-search active">
            <i
              id="user-search-close"
              className="ui icon times"
              onClick={() => setSearchActive(false)}
            ></i>
            <div className="ui search">
              <div className="ui icon input">
                <input
                  className="prompt"
                  type="text"
                  placeholder="Search for users..."
                  onChange={(e) => setSearchQuery(e.target.value)}
                />
                <i className="search icon"></i>
              </div>
            </div>
            {!searchLoading && searchResults.length > 0 && (
              <FollowList
                isFollowing={true}
                requestUserId={user.id}
                profileUserId={user.id}
                users={searchResults}
              />
            )}
            {searchResults.length === 0 && (
              <div className="ui big middle aligned relaxed divided list">
                {searchLoading && <div className="item">Loading...</div>}
                {searchQuery &&
                  !searchLoading &&
                  searchResults.length === 0 && (
                    <div className="item">
                      No users found for search term "{searchQuery}"
                    </div>
                  )}
              </div>
            )}
          </div>
        )}
      </>
    );
  } else {
    HeaderInner = (
      <div className="ui top fixed secondary menu">
        <a className="item" href="/beta/">
          <h1>Fabric</h1>
        </a>
        <Menu.Menu position="right">
          <Menu.Item href="/auth/login/">Log in</Menu.Item>
          <Menu.Item href="/auth/signup/">Sign up</Menu.Item>
        </Menu.Menu>
      </div>
    );
  }
  return createPortal(HeaderInner, headerEl);
};

const DarkFooter = () => {
  const [isDark, setIsDark] = useState(IS_DARK_INITIAL);
  useEffect(() => {
    setCookie("dark", isDark ? "" : "disabled");
    const bodyEl = document.getElementsByTagName("body")[0];
    if (isDark) {
      bodyEl.classList.add("dark");
    } else {
      bodyEl.classList.remove("dark");
    }
  }, [isDark]);
  return (
    <FooterEl>
      <Checkbox
        toggle
        label="Dark mode"
        checked={isDark}
        onChange={(e, data) => setIsDark(data.checked)}
      />
    </FooterEl>
  );
};

const FooterEl = styled.div`
  position: fixed;
  height: 30px;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 2;
  background-color: var(--white);
  padding: 5px var(--content-padding);
  display: flex;
  justify-content: end;
  body.dark & {
    background-color: var(--dark-grey);
    .ui.checkbox.toggle.checked label {
      color: var(--white) !important;
    }
  }
`;
