import React from "react";
import styled from "styled-components";
import moment from "moment";
import { Dropdown } from "semantic-ui-react";

import { URLS } from "consts";

export const PostCard = ({ post, editEnabled, onHidePost, onDeletePost }) => {
  return (
    <PostEl>
      {(onHidePost || onDeletePost || editEnabled) && (
        <div className="user-edit">
          <Dropdown
            icon="ellipsis horizontal"
            className="icon bottom right pointing button"
          >
            <Dropdown.Menu>
              {onHidePost && (
                <Dropdown.Item
                  icon="eye slash outline"
                  text="Hide post"
                  onClick={onHidePost(post.id)}
                />
              )}
              {editEnabled && (
                <Dropdown.Item
                  href={`/app/post/${post.id}/`}
                  icon="edit"
                  text="Edit post"
                />
              )}
              {onDeletePost && (
                <Dropdown.Item
                  icon="times"
                  text="Delete post"
                  onClick={onDeletePost(post.id)}
                />
              )}
            </Dropdown.Menu>
          </Dropdown>
        </div>
      )}
      <a href={post.url} target="_blank" rel="noopener noreferrer nofollow">
        {post.image_url ? (
          <img className="hero" src={post.image_url} />
        ) : (
          <img className="hero" />
        )}
      </a>
      <div className="details">
        <p className="title">{post.title}</p>
        <a href={post.creator.url}>
          <img
            src={
              post.alt_creator_image_url
                ? post.alt_creator_image_url
                : post.creator.image_url
            }
            className="ui circular image"
          />
          <span className="info">
            <span className="username">
              {post.alt_display_name
                ? post.alt_display_name
                : post.creator.name}
            </span>
            <span className="timestamp">
              {post.alt_description
                ? post.alt_description
                : moment(post.created_at).fromNow()}
            </span>
          </span>
        </a>
      </div>
    </PostEl>
  );
};

const PostEl = styled.div`
  width: 100%;
  position: relative;
  font-family: Arial, Helvetica, sans-serif;
  &:before {
    content: "";
    display: block;
    height: 0;
    width: 0;
    padding-bottom: calc(16 / 9 * 100%);
  }

  .details {
    border-radius: 0.5rem;
    width: 100%;
    padding: 2rem 1rem 0.5rem 1rem;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
    background: linear-gradient(
      0deg,
      rgba(102, 102, 102, 0.8) 0%,
      rgba(102, 102, 102, 0) 100%
    );
    z-index: 1;
  }

  .details a {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    padding-top: 00.5rem;
  }

  .details img {
    width: 28px;
    height: 28px;
    object-fit: cover;
  }

  .details a,
  .details {
    color: #fff;
  }

  .title {
    font-weight: bold;
    margin-bottom: 0;
  }
  .details .info {
    display: flex;
    flex-direction: column;
  }
  .username {
    text-decoration: none;
  }
  .timestamp {
    opacity: 0.8;
    font-size: 0.8rem;
    margin-top: -4px;
  }

  img.hero {
    border-radius: 0.5rem;
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #bbb;
  }

  .user-edit {
    position: absolute;
    right: 0;
    bottom: 0;
    z-index: 2;
  }
  .user-edit .ui.icon.button {
    border-bottom-right-radius: 0.8rem;
    border-top-right-radius: 0;
    border-bottom-left-radius: 0rem;
    margin: 0;
    background-color: transparent !important;
    color: #fff;
  }
`;

export const PostEmpty = () => (
  <EmptyEl>
    No posts here yet, try <a href={URLS.CREATE}>sending a Postcard</a>.
  </EmptyEl>
);

const EmptyEl = styled.p`
  margin: 0 auto;
  grid-column: 1 / -1;
`;

export const PostList = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 1.2rem;
  @media (max-width: 1200px) {
    grid-template-columns: 1fr 1fr 1fr;
  }
  @media (max-width: 900px) {
    grid-template-columns: 1fr 1fr;
  }
  @media (max-width: 700px) {
    grid-template-columns: 1fr;
  }
  ${({ preview }) =>
    preview &&
    `
    grid-template-columns: 1fr !important;
    width: 320px;
    padding: 0 1rem 1rem 1rem;
  `}
`;
