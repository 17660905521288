import React, { useEffect } from "react";
import { hydrate, render } from "react-dom";

export const getCookie = (name) => {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) {
    return parts.pop().split(";").shift();
  }
};

export const setCookie = (name, value) => {
  document.cookie = name + "=" + (value || "") + "; path=/";
};

export const mount = (App, rootId) => {
  const root = document.getElementById(rootId);
  if (root.hasChildNodes()) {
    hydrate(<App />, root);
  } else {
    render(<App />, root);
  }
};

export const useOutsideClick = (ref, onClickOutside) => {
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        onClickOutside();
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref]);
};

export const intWithCommas = (x) => {
  const rounded = Math.round(Number(String(x).replace(",", "")));
  if (isNaN(rounded)) {
    return "";
  } else {
    return rounded.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }
};

export const debounce = (delay) => {
  let timer = null;
  return (func) => {
    return (...args) => {
      clearTimeout(timer);
      timer = setTimeout(() => func(...args), delay);
    };
  };
};

// Debounce user input, returns a promise
export const debouncePromise = (delay) => {
  let timer = null;
  return (func) => {
    return (...args) =>
      new Promise((resolve) => {
        clearTimeout(timer);
        timer = setTimeout(() => func(...args).then(resolve), delay);
      });
  };
};

// Wait n seconds
export const waitSeconds = (delay) =>
  new Promise((resolve) => setTimeout(() => resolve(), delay * 1000));
