import { URLS } from "consts";
import { getCookie } from "utils";

const BASE_CONFIG = {
  method: null,
  credentials: "include",
  headers: {
    "X-React": "true",
    "X-CSRFToken": getCookie("csrftoken"),
    "Content-Type": "application/json",
  },
};

const handleResponse = async (resp) => {
  let data = {};
  if (resp.status !== 204) {
    try {
      data = await resp.json();
    } catch {
      console.error("Could not parse response JSON.");
    }
  }
  return { resp, data };
};

const sendData = async (url, data, method, headers = {}) => {
  const config = {
    ...BASE_CONFIG,
    headers: { ...BASE_CONFIG.headers, ...headers },
    method: method,
  };
  if (config.headers["Content-Type"] === "application/json") {
    config.body = JSON.stringify(data);
  } else if (config.headers["Content-Type"] === "multipart/form-data") {
    config.body = new FormData();
    for (let [k, v] of Object.entries(data)) {
      if (Array.isArray(v) && v.length === 0) {
        continue;
      } else {
        config.body.append(k, v);
      }
    }
    delete config.headers["Content-Type"];
  }
  const resp = await fetch(url, config);
  return handleResponse(resp);
};

const http = {
  post: (url, data, headers = {}) => sendData(url, data, "POST", headers),
  patch: (url, data, headers = {}) => sendData(url, data, "PATCH", headers),
  put: (url, data, headers = {}) => sendData(url, data, "PUT", headers),
  get: async (url, query) => {
    let finalURL = url;
    if (query) {
      const qs = new URLSearchParams(query).toString();
      finalURL = `${url}?${qs}`;
    }
    const config = { ...BASE_CONFIG, method: "GET" };
    const resp = await fetch(finalURL, config);
    return handleResponse(resp);
  },
  delete: async (url) => {
    const config = { ...BASE_CONFIG, method: "DELETE" };
    const resp = await fetch(url, config);
    return handleResponse(resp);
  },
};

export const api = {
  user: {
    search: (query) => {
      const url = "/app/user/search/";
      return http.get(url, { search: query });
    },
    updateProfile: (userId, data) => {
      const url = `/app/user/${userId}/profile/`;
      return http.post(url, data, { "Content-Type": "multipart/form-data" });
    },
    toggleFollower: (userId) => {
      const url = `/app/user/${userId}/followers/remove/`;
      return http.post(url, {});
    },
    toggleFollow: (userId) => {
      const url = `/app/user/${userId}/follow/`;
      return http.post(url, {});
    },
  },
  post: {
    create: (data) => {
      const url = `/app/post/`;
      return http.post(url, data, { "Content-Type": "multipart/form-data" });
    },
    update: (postId, data) => {
      const url = `/app/post/${postId}/`;
      return http.post(url, data);
    },
    delete: (postId) => {
      const url = `/app/post/${postId}/`;
      return http.delete(url);
    },
    hide: (postId) => {
      const url = `/app/user/${postId}/hide/`;
      return http.post(url, {});
    },
  },
};
