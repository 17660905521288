import React, { useState } from "react";
import { Header } from "semantic-ui-react";

import { api } from "api";

export const FollowList = ({
  isFollowing,
  requestUserId,
  profileUserId,
  users,
}) => {
  return (
    <div className="ui  middle aligned relaxed divided list">
      {users.length === 0 && <div className="item">No results</div>}
      {users.map((user) => (
        <div className="item" key={user.id}>
          <img src={user.image_url} className="ui avatar image" />
          <div className="content">
            <a href={user.url} className="header">
              {user.name}
            </a>
            <div
              className="description"
              style={{ opacity: 0.6, fontSize: "0.8em" }}
            >
              {user.username}
            </div>
          </div>
          <div className="right floated content">
            {requestUserId !== user.id && (
              <FollowButton isFollowingInitial={user.is_followed} user={user} />
            )}
            {!isFollowing && requestUserId === profileUserId && (
              <RemoveFollowerButton user={user} />
            )}
          </div>
        </div>
      ))}
    </div>
  );
};

export const RemoveFollowerButton = ({ user }) => {
  const [isFollower, setIsFollower] = useState(true);
  const onClick = () => {
    api.user.toggleFollower(user.id).then(({ resp, data }) => {
      if (resp.ok) {
        setIsFollower(data.is_follower);
      }
    });
  };
  return (
    <button className="ui button yellow compact" onClick={onClick}>
      {isFollower ? "Remove" : "Undo"}
    </button>
  );
};

export const FollowButton = ({ isFollowingInitial, user }) => {
  const [isFollowing, setIsFollowing] = useState(isFollowingInitial);
  const onClick = () => {
    api.user.toggleFollow(user.id).then(({ resp, data }) => {
      if (resp.ok) {
        setIsFollowing(data.is_following);
      }
    });
  };
  return (
    <button className="ui button primary compact" onClick={onClick}>
      {isFollowing ? "Unfollow" : "Follow"}
    </button>
  );
};

export const UserHero = ({
  title,
  user,
  countFollowing,
  countFollowers,
  isUpdate,
}) => (
  <div className="user-hero">
    <a href={user.url} className="profile-image">
      <img src={user.image_url} />
    </a>
    <Header as="h1" textAlign="center" style={{ marginTop: 0 }}>
      {title}
      {isUpdate && <Header.Subheader>Update your profile</Header.Subheader>}
      {!isUpdate && (
        <Header.Subheader>
          <a style={{ color: "inherit" }} href={user.following_url}>
            {countFollowing} Following
          </a>
          &nbsp;&nbsp;
          <a style={{ color: "inherit" }} href={user.followers_url}>
            {countFollowers} Followers
          </a>
        </Header.Subheader>
      )}
    </Header>
  </div>
);
